/**
 * @license
 * Copyright 2017 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { nameCompare } from '../util/util';
import { NamedNode } from './Node';

export function NAME_ONLY_COMPARATOR(left: NamedNode, right: NamedNode) {
  return nameCompare(left.name, right.name);
}

export function NAME_COMPARATOR(left: string, right: string) {
  return nameCompare(left, right);
}
